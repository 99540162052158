import React from 'react';
import { ERROR_RESPONSE_TYPES } from '@src/utils/errorHandler';

import ErrorPage from './../_error';

class Maintenance extends React.Component<{}, {}> {
  render() {
    return <ErrorPage
      statusCode={404}
      errorResponseType={ERROR_RESPONSE_TYPES.OTHERS_TYPES}
      sentryMsg={'No support SDK'}
      message={'無許可'} />;
  }
}

export default Maintenance;
